import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UrlShortenService } from '../url-shorten.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  // @Output() valueChange = new EventEmitter();
  // @Output() showHide: boolean;
  username: any;
  password: any;
  userData = {
    "edelweiss": "edelweiss@123",
    "rahul": "rahul",
    "hemant": "edelweiss@123",
    "admin": "edelweiss@123"
  }
  token: any;
  loginBody: any;
  tokenBody: any;
  adAccessToken: any;
  verifyTokenBody: any;
  // adPath: any = "http://localhost:4200"
  adPath: any = "https://tinyurl-uat.edelweissfin.com"
  etlifeFlag: boolean = false;
  constructor(private router: Router, private service: MessageService, private route: ActivatedRoute, private serv: UrlShortenService,private spinner: NgxSpinnerService) {
    // console.log('Called Constructor');
    localStorage.clear();
    this.route.queryParams.subscribe(params => {
      localStorage.clear();
      var code = params['code'];
      // console.log("code => ", code)
      if (code) {
        this.spinner.show()
        this.serv.activeDirectorytokenAPI().subscribe(res => {
          
          // console.log("Response TOKEN ",res);
          this.token = res["access_token"];
          // console.log("token => ", this.token)
          this.tokenBody = {
            "clientId": "419klqplnet7p7p775fk3c6789",
            "redirectUrl": this.adPath,
            "code": code,
            "clientSecret": "1li0og2udod9f7m3l7rlip4o4st7lrs7a6hf0cne8fsnlqtn4q0g"
          }
          this.serv.activeDirectoryToken(this.tokenBody, this.token).subscribe(res => {
            // console.log("activeDirectoryToken res => ", res)
            this.adAccessToken = res["data"]["access_token"]
            this.verifyTokenBody = {
              "accessToken" : this.adAccessToken 
            }
            this.serv.activeDirectoryVerifyToken(this.verifyTokenBody, this.token).subscribe(res => {
              // console.log("activeDirectoryVerifyToken => ",res)
              let flag = res["data"]["status"]
              if(flag == true){
                // console.log("Active Directory User")
                
                this.serv.activeDirectoryUserInfo(this.verifyTokenBody, this.token).subscribe(res => {
                  // console.log("activeDirectoryUserInfo => ",res)
                  let name = res["data"]["given_name"] + " " + res["data"]["family_name"]
                  let email = res["data"]["email"]

                  // console.log("Logged in user name => ",name)
                  // console.log("email =>",email)
                  localStorage.setItem('SessionUser', 'SessionEstablished')
                  localStorage.setItem('SessionUserName', name)
                  localStorage.setItem('SessionUserEmail', email)
                  this.spinner.hide()
                  this.router.navigate(['tiny-url']);

                },(error)=>{
                  console.log("Active Directory user details not found ",error)
                  this.spinner.hide()
                this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Current logged in user details not found.' });
                })
              }
              else{
                // console.log("Not a Active Directory User")
                this.spinner.hide()
                this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Please raise a request to add current logged in user in Active Directory.' });
              }
            }, (error) => {
              console.log("Error occurred in Code Token ", error);
              this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Unable to connect to Active Directory at this moment, Please try again later.' });
            })
          }, (error) => {
            this.spinner.hide()
            console.log("Error occurred in Code Token ", error);
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Unable to connect to Active Directory at this moment,Please try again later.' });
          })
        }, (error) => {
          this.spinner.hide()
          console.log("Error occurred in Code Access Token ", error);
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please try again later.' });
        })
      }
    });
  }

  ngOnInit() {

    (document.getElementById('appTopbar') as HTMLElement).style.display = "none";
    // this.valueChange.emit(false);
    // this.showHide = false;
  }
  etlifeADLoginClicked(){
    this.etlifeFlag = true;
    this.ADLoginClicked()
  }
  ADLoginClicked() {
    localStorage.clear();
    // console.log("Active Directory Sign In")
    this.serv.activeDirectorytokenAPI().subscribe(res => {
      // console.log("Response TOKEN ",res);
      this.token = res["access_token"];
      // console.log("token => ", this.token)
      let adEmail = ""
      if(this.etlifeFlag){
        adEmail = "test@etlife.in"
      }
      else{
        adEmail = "rahul.chaurasiya@edelweissfin.com"
      }
      this.loginBody = {
        "redirectUrl": this.adPath,
        "clientId": "419klqplnet7p7p775fk3c6789",
        "loginVia": "Edelweiss",
        "email": adEmail
      }
      this.serv.activeDirectoryLogin(this.loginBody, this.token).subscribe(res => {
        // console.log("Login Response=>", res)
        let loginUrl = res["data"]["url"]
        // console.log("loginUrl => ", loginUrl)
        window.open(loginUrl, "_self")
      }, (error) => {
        console.log("Error occurred in Login ", error)
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please try again later.' });
      })
    }, (error) => {
      console.log("Error occurred in Token ", error);
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please try again later.' });
    })
  }

  LoginClicked() {
    localStorage.clear();
    // console.log("LOGIN CLICKED")
    if (this.userData[this.username]) {
      if (this.userData[this.username] == this.password) {
        // localStorage.setItem('SessionUser', 'SessionEstablished')
        // console.log("Username password is correct")  
        localStorage.setItem('SessionUser', 'SessionEstablished')
        localStorage.setItem('SessionUserName', 'Edelweiss Admin Account')
        localStorage.setItem('SessionUserEmail', 'NA')
        this.router.navigate(['tiny-url']);
      }
      else {
        // console.log("Password is Incorrect")
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please enter correct password.' });
        // this.toastr.error("Please enter correct password.")
      }
    }
    else {
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please enter correct username and password.' });
      // this.toastr.error("Please enter correct username and password.")
      // console.log("Username Password is Incorrect")
    }
    // console.log("Login button clicked");
  }
}
