import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuardService } from './auth-guard.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private Authguardservice: AuthGuardService, private router: Router) {}
  canActivate(): boolean {
    // console.log("Inside Authentication Guard ")
    // console.log("this.Authguardservice.gettoken() =>",this.Authguardservice.gettoken())
    // console.log(localStorage.getItem("SessionUser"))
      if (!this.Authguardservice.gettoken()) {  
        this.router.navigate(['']);  
    }  
      return this.Authguardservice.gettoken(); 
    // return true;
  }
  
}
