import { Component } from '@angular/core';
import { AppComponent} from './app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
    
    // @Input() showHide: boolean;
    constructor(public app: AppComponent,private router: Router) {
      // localStorage.setItem('SessionUserName', 'Edelweiss Admin Account')
      // localStorage.setItem('SessionUserEmail', 'NA')
    }
    get name(): any {
      return localStorage.getItem("SessionUserName");
    }
    get email():any {
      return localStorage.getItem("SessionUserEmail");
    }
    LogoutClicked(){
      localStorage.removeItem("SessionUser");
      localStorage.clear();
      this.router.navigate(['']);
    }
    HomeClicked(){
      localStorage.setItem('SessionUser', 'SessionEstablished')
      // console.log("Home Clicked")
      this.router.navigate(['tiny-url']);
    }
    URLShortenClicked(){
      localStorage.setItem('SessionUser', 'SessionEstablished')
      // console.log("URL Clicked")
      this.router.navigate(['tiny-url-list']);
    }
}
