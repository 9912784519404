import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UrlShortenService {
  apiKey: any = 'CDslVie7EsaxQxZK8cxjV257EkMYWzQp8RoEWUTr'
  //DLP UAT API KEY
  // apiKey: any = 'rFxhjYiPpz1oRpXvtIUx75eE6PzWGWECojKqnfL6'
  basePath: any = 'https://devapi.edelweissfin.com/'
  apiKeyAD: any = 'ZD1r80965y7CjxjdXcllqaTURmtocofP6bDVyPHM';
  constructor(private http: HttpClient) { }

  // getIPAddress()
  // {
  //  return this.http.get("https://jsonip.com/");
  // }
  
  getIPAddress()
  {
   return this.http.get("https://api.ipify.org/?format=json");
  }

  activeDirectorytokenAPI(){
    let url = 'https://apis.edelweissfin.com/oauth2/token';
    let body = {};

    return this.http.post(url,body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic NnY5bm1rczBzMXNscDlyaG1rZDU1ZmtsN2I6MWdmYnBhZ3VvdjVwczMwNW1lOTU2dm9mODR2ZWszbTlobGgwczAyMnFuYTkxODIycGRjcA=='
      })});
  }
  activeDirectoryLogin(body, token){
    // console.log("BODY =>",body)
    return this.http.post('https://apis.edelweissfin.com/auth/login',body,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKeyAD
      })})
  }
  activeDirectoryToken(body, token){
    // console.log("BODY =>",body)
    return this.http.post('https://apis.edelweissfin.com/auth/token',body,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKeyAD
      })})
  }
  activeDirectoryVerifyToken(body, token){
    // console.log("BODY =>",body)
    return this.http.post('https://apis.edelweissfin.com/auth/verifyToken',body,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKeyAD
      })})
  }
  activeDirectoryUserInfo(body, token){
    // console.log("BODY =>",body)
    return this.http.post('https://apis.edelweissfin.com/auth/userInfo',body,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKeyAD
      })})
  }

  tokenAPI(){
    let url = this.basePath+'oauth2/token';
    let body = {};

    return this.http.post(url,body, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic NTk1Y2hqZjRuNXJmdWp0N2owc2dzOXVqbHQ6MTR1OHJ1dXJjOWZyY2x1NjVydmhxNzMwMTNkdjIxcTNxdmI3bGtpNGJjYmNlOTVmMmMycg=='
      })});
  }

  //DLP UAT Credentials
  // tokenAPI(){
  //   let url = this.basePath+'oauth2/token';
  //   let body = {};

  //   return this.http.post(url,body, {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': 'Basic NmFycWRuZTJzY2U3dXIwOGFlcW5kdTVyZzQ6cDBxMzNmOTY4M2QydHBkNzRxcG45dmQzaXQ2MXZ0bDUybHQ4NzBqNHAzN3NhZ3E0MjRz'
  //     })});
  // }

  addNewShortURL(body, token){
    // console.log("BODY =>",body)
    return this.http.post(this.basePath+'tinyurl',body,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKey
      })})
    // return this.http.post('https://k8n0xwobt9.execute-api.ap-south-1.amazonaws.com/default/short-url/add',body)
  }

  // checkURLalreadyExist(body){
  //   return this.http.post('https://k8n0xwobt9.execute-api.ap-south-1.amazonaws.com/default/short-url/getbyactualurl',body)
  // }

  editLongURL(body, token){
    
    return this.http.post(this.basePath+'tinyurl',body,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKey
      })})
  }

  getAllList(token){
    return this.http.get(this.basePath+'tinyurl',{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKey
      })});
    // return this.http.get('https://k8n0xwobt9.execute-api.ap-south-1.amazonaws.com/default/');
  }
  getConfig(token){
    return this.http.get(this.basePath+'tinyurl?config=true',{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKey
      })});
  }
  deleteTinyURL(requestId, token){
    return this.http.delete(this.basePath+'tinyurl/'+requestId,{
      headers: new HttpHeaders({
        'Authorization': token,
        'x-api-key': this.apiKey
      })});
    
  }
}
