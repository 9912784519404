import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UrlShortenService } from '../url-shorten.service';
// import { Message } from 'primeng/primeng';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpParams } from '@angular/common/http';
@Component({
  selector: 'app-url-add',
  templateUrl: './url-add.component.html',
  styleUrls: ['./url-add.component.css'],
  providers: [MessageService]
})
export class UrlAddComponent implements OnInit {
  resp: any;
  actual_url: any;
  suggest_url: any;
  shorten_url: any;
  token: any = '';
  aliasRequestId: any;
  //OLD
  // regex:any = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
  
  // regex:any = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
  //regex:any = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
  // regex: any = /(https?):\/\/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
  regex: any = /^(https?):\/\/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
  // msgs: Message[] = [];
  // domain: any = "https://k8n0xwobt9.execute-api.ap-south-1.amazonaws.com/default/";
  body: any = {
    "url": "",
    "ip": "",
    "createdBy": "",
    "creatorEmail": "",
    "alias": ""
  }
  dialogVisible: boolean;
  name: string;
  email: string;

  constructor(private serv: UrlShortenService,  private router: Router, private service: MessageService,private spinner: NgxSpinnerService) {
    this.name = localStorage.getItem("SessionUserName")
    this.email = localStorage.getItem("SessionUserEmail")
    // console.log("NAME => ",this.name)
    // console.log("EMAIL => ",this.email)
   }

  ngOnInit(): void {
    localStorage.removeItem("SessionUser");
    (document.getElementById('appTopbar') as HTMLElement).style.display = "block";
    

    navigator.clipboard.readText()
  .then(text => {
    // console.log('Pasted content: ', text);
    // var regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var isURL = this.regex.test(text);
    // console.log("isURL : ",isURL)
    if(isURL){
      if(text.includes("https://edlws.in/") || text.includes("tinyurl.edelweissfin.com") || text.includes("tinyurl-uat.edelweissfin.com")){
        // console.log("https://edlws.in URL copied")
      }
      else{
      this.suggest_url = text;
      this.dialogVisible = true;
      }
    }
  })
  .catch(err => {
    console.error('Failed to read clipboard contents: ', err);
  });
  }

  suggestionYesClick(){
    this.dialogVisible=false;
    this.actual_url = this.suggest_url;
    this.shortenBtnClick();
  }
  
  async shortenBtnClick() {
    
    this.regex = /^(https?):\/\/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
    
    // var regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var isURL = this.regex.test(this.actual_url);
    if(isURL){
    this.spinner.show();
    if (this.actual_url) {
      
    if(this.aliasRequestId){
      // console.log("IF ALIAS => ",this.aliasRequestId)
      // let aliasRegex = /^(?!-)(?!_)(?!.*--)(?!.*__)[A-Za-z0-9-_]+(?<!-)(?<!_)$/
      // let aliasRegex = /^(?!-)(?!_)(?!.*--)(?!.*__)[A-Za-z0-9-_]+(?<!-)(?<!_).{2,20}$/
      
      let aliasRegex;
      aliasRegex = /^(?!-)(?!_)(?!.*--)(?!.*__)[a-zA-Z0-9\-_]{2,40}$/
      // if(this.aliasRequestId.includes("?")){
      //   aliasRegex = /^(?!&)(?!=)(?![?])(?!-)(?!_)(?!.*--)(?!.*__)(?!.*&&)(?!.*==)(?!.*[?][?])(?!.*[?]=)(?!.*=[?])(?!.*&=)(?!.*[?].*[?])(.*[?].*([=].*)*)((?=.*=).+)[a-zA-Z0-9\-_?=&]{2,40}$/
      //   // console.log("this.aliasRequestId => ",this.aliasRequestId)
      //   let url = "https://www.edelweiss.com/"+this.aliasRequestId
      //   let d = new URL(url);
      //   // console.log("data=>",d)
      //   let pathParam = d.pathname.replace("/","")
      //   let queryString = d.search.replace("?","")
      //   // console.log("queryString => ",queryString)
      //   var searchParams = new URLSearchParams(queryString);
      //   searchParams.sort();
      //   let finalQueryString = searchParams.toString();
      //   this.aliasRequestId = pathParam+"?"+finalQueryString
      //   // console.log("final after processing=> ",this.aliasRequestId)
        
      // }
      // else{
      //   aliasRegex = /^(?!-)(?!_)(?!.*--)(?!.*__)[a-zA-Z0-9\-_]{2,40}$/
      // }
      //TEsting
      // this.spinner.hide();
      // return 
     
      // "/^[a-zA-Z0-9\-_]{0,40}$/";
      var isCorrectAlias = aliasRegex.test(this.aliasRequestId);
      if(isCorrectAlias){
        // console.log("IF INSIDE ALIAS => ",this.aliasRequestId)
        if(this.aliasRequestId.length>40){
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please pass valid alias [must contain less than 40 character] ' });
          this.spinner.hide()
          return;
        }
      }
      else{
        // console.log("ELSE ALIAS => ",this.aliasRequestId)
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please pass valid alias ' });
        this.spinner.hide()
        return;
      }
    }
      // var key = this.createCaptcha();

      // this.body.short_url_key = key;
      // this.body.domain = this.domain;
      this.body.url = this.actual_url;
      let resIP 
      try{
        resIP = await this.serv.getIPAddress().toPromise();
        this.body.ip = resIP["ip"]
      }
      catch(error){
        // console.log("Exception handled")
      }
      this.body.createdBy = this.name;
      this.body.creatorEmail = this.email;
      this.body.alias = this.aliasRequestId;
      // this.serv.getIPAddress().subscribe(res=>{
      //   console.log("IP address => "+res)
      //   this.body.ip = res["ip"]
      // },(err) => {
      //   console.log("Error occurred while retriving IP "+err)
      // })
      this.serv.tokenAPI().subscribe(res=>{
        // console.log("Response TOKEN ",res);
        this.token = res["access_token"];
        // console.log("Inside Create => ",this.body)
        this.serv.addNewShortURL(this.body,this.token).subscribe(res => {
          // console.log("Response Generate => ",res)
          // console.log("actual url => ",this.actual_url)
          // this.shorten_url = res["tinyUrl"]
          // console.log("ressss => ",res)
          this.spinner.hide();
          // this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: res["message"]+", Please copy tiny url to use." });
          if(res["message"] == "Invalid URL passed."){
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: res["message"] });
          }
          else{
            // this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: res["message"]+"." });
            // this.copyBtnClick()
            if(this.aliasRequestId){
              if(res["actualUrl"]===this.actual_url){
                this.shorten_url = res["tinyUrl"]
                this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: res["message"]+"." });
                this.copyBtnClick()
              }
              else{
                this.shorten_url = ""
                this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: "Please use different alias as it is already map to : "+res["actualUrl"] +" ." });
              
              }
            }
            else{
              this.shorten_url = res["tinyUrl"]
              this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: res["message"]+"." });
              this.copyBtnClick()
            }
          }
          
        },
          (err) => {
            this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API Server is down, Please try again later!' });
            this.spinner.hide();
          });

      },
      (err) => {
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API Server is down, Please try again later!' });
        this.spinner.hide();
      });

      
      
      // this.serv.checkURLalreadyExist({ "actual_url": this.actual_url }).subscribe(res => {

      //   var checkURLExist = res["body"];
      //   if (checkURLExist.length == 0) {
      //     this.shorten_url = this.domain + key;
      //     this.serv.addNewShortURL(this.body).subscribe(res => {
      //       this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: 'URL shorten successfull, Please copy below URL to use.' });
      //     },
      //       (err) => {
      //         this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Server is down, Please try again later!' });
      //       });

      //   }
      //   else {
      //     this.shorten_url = checkURLExist[0].domain + checkURLExist[0].short_url_key;

      //     this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: 'Given URL is already shortened, Please copy below URL to use.' });
      //     // this.toastr.success("Given URL is already shortened, Please copy below URL to use.")
      //   }

      // },
        // (err) => {
        //   this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Server is down, Please try again later!' });
        // })

    }
    else {
      this.shorten_url = null;
      this.service.add({ key: 'tst', severity: 'warn', summary: 'Warn Message', detail: 'Please paste url in the textbox to create tiny url.' });
      this.spinner.hide();
      // this.toastr.error("Please paste url in the textbox to create short url.")
    }}
    else{
      // console.log("isURL=>",isURL)
      // console.log("this actuaol url => ",this.actual_url)
      this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Please pass valid Long URL' });
    }

  }
  copyBtnClick() {
    if (this.shorten_url) {

      const selBox = document.createElement('textarea');
      selBox.value = this.shorten_url;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'Copied - ' + this.shorten_url });
      // this.toastr.show("Copied - " + this.shorten_url)
    }
    else {
      this.service.add({ key: 'tst', severity: 'warn', summary: 'Warn Message', detail: 'Please click Make tinyURL! button to create tiny url.' });
      // this.toastr.error("Please click Shorten button to create short link.")
    }

  }

  createCaptcha() {
    var alpha = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
      'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']


    //  for (var i=0;i<6;i++){
    var a = alpha[Math.floor(Math.random() * alpha.length)];
    var b = alpha[Math.floor(Math.random() * alpha.length)];
    var c = alpha[Math.floor(Math.random() * alpha.length)];
    var d = alpha[Math.floor(Math.random() * alpha.length)];
    var e = alpha[Math.floor(Math.random() * alpha.length)];
    var f = alpha[Math.floor(Math.random() * alpha.length)];
    var g = alpha[Math.floor(Math.random() * alpha.length)];
    // }

    var code = a + b + c + d + e + f + g;

    return code;

  }

  URLShortenListBtnClick(){
    // localStorage.setItem('SessionUser', 'SessionEstablished')
    this.router.navigate(['tiny-url-list']);
  }

}
