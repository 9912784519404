import { Component, OnInit } from '@angular/core';
import { UrlShortenService } from '../url-shorten.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { Table } from 'primeng/table';
// import { convertUpdateArguments } from '@angular/compiler/src/compiler_util/expression_converter';
@Component({
  selector: 'app-url-list',
  templateUrl: './url-list.component.html',
  styleUrls: ['./url-list.component.css']
})
export class UrlListComponent implements OnInit {
  cols: any[];
  listData: any = [];
  selectedUrl: any;
  data: any;
  // apiKey: any = 'caLzQiZP7KahXx78Pvouy88DbkuqJjim7ZWamNXd';
  token: any = '';
  datePipe = new DatePipe('en-US');
  ip: any;
  email: any;
  dialogVisible: boolean;
  urlDetailsPopupHeader: string;
  hitHistoryList: any = [];
  hitHistoryCols: any[];
  temp: any = [];
  deleteDialogVisible: boolean;
  deleteRow: any;
  row: {};
  datacopy: any;
  userName: any = localStorage.getItem("SessionUserName");
  userEmail: any = localStorage.getItem("SessionUserEmail");
  editDialogVisible: boolean;
  domain: any;
  longUrl: any;
  requestId: any;
  compLongURL: any;
  editRow: any;
  regex: any = /^(https?):\/\/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ig;
  dateReg:any = /(\d{2}([.\-/])\d{2}([.\-/])\d{2}[ ]\d{2}[:]\d{2})/g
  constructor(private serv: UrlShortenService,private service: MessageService, private router: Router,private spinner: NgxSpinnerService) { }

  ngOnInit() {
    localStorage.removeItem("SessionUser");
    (document.getElementById('appTopbar') as HTMLElement).style.display = "block";
    this.hitHistoryCols = [
      { field: 'hit', header: 'Hit Count'},
      { field: 'queryString', header: 'Query String'},
      { field: 'time', header: 'Hit Time'},
      { field: 'clientIP', header: 'IP Address'}
    ];
    this.cols = [
      { field: 'shorten_url', header: 'Tiny URL' },
      { field: 'actual_url', header: 'Long URL' },
      { field: 'apiKeyDesc', header: 'Description'},
      { field: 'created_on', header: 'Created Date' },
      { field: 'createdBy', header: 'Created By'},
      { field: 'hitCount', header: 'Total Hits' },
      { field: 'lastHitDate', header: 'Last Hit Time' } 
      
      // { field: 'creatorEmail', header: 'Email ID'},
      // { field: 'creatorIPAddress', header: 'IP Address' }
  ];
  
    this.fetchAllDetails()
  }

  HomeBtnClick(){
    // console.log("Home Button click")
    // localStorage.setItem('SessionUser', 'SessionEstablished')
    // this.router.navigate(['']);
    this.router.navigate(['tiny-url']);
  }

  fetchAllDetails(){
    this.spinner.show();
    this.serv.tokenAPI().subscribe(res=>{
      // console.log("Response TOKEN ",res);
      this.token = res["access_token"];
      this.serv.getAllList(this.token).subscribe(res => {
        
        var resp :any;
        resp = res;
        
        this.listData = []
        this.data = []
        resp.forEach(element => {
          var respFormat = {
            "actual_url": "",
            "shorten_url": "",
            "created_on": "",
            "hitCount": "",
            "lastHitDate": "",
            "creatorIPAddress": "",
            "creatorEmail": "",
            "createdBy": "",
            "hitHistory": "",
            "apiKey": ""
  
          };
          respFormat.actual_url = element.actualUrl;
          respFormat.shorten_url = element.tinyUrl;
          // respFormat.created_on = element.createdDate  ? new Date(element.createdDate).toLocaleString() : element.createdDate;
          // respFormat.created_on = element.createdDate  ? moment(new Date(element.createdDate)).fromNow()+ " ("+new Date(element.createdDate).toLocaleString()+")" : element.createdDate;
          respFormat.created_on = element.createdDate  ? moment(new Date(element.createdDate)).fromNow()+ "\n" + this.datePipe.transform(new Date(element.createdDate),"dd-MM-yy HH:mm") : element.createdDate;
          
          // respFormat.created_on = element.createdDate  ? moment(new Date(element.createdDate)).fromNow()+ " ("+this.datePipe.transform(new Date(element.createdDate),"dd/MM/yy HH:mm")+")" : element.createdDate;
          
          
          respFormat.hitCount = element.hitCount;
          // respFormat.lastHitDate = element.lastHitDate ? new Date(element.lastHitDate).toLocaleString() : element.lastHitDate;
          // respFormat.lastHitDate = element.lastHitDate ? moment(element.lastHitDate).fromNow()+ " ("+new Date(element.lastHitDate).toLocaleString()+")" : element.lastHitDate;
          respFormat.lastHitDate = element.lastHitDate ? moment(element.lastHitDate).fromNow()+ "\n" + this.datePipe.transform(new Date(element.lastHitDate),"dd-MM-yy HH:mm") : element.lastHitDate;
          
          // respFormat.lastHitDate = element.lastHitDate ? moment(element.lastHitDate).fromNow()+ " ("+this.datePipe.transform(new Date(element.lastHitDate),"dd/MM/yy HH:mm")+")" : element.lastHitDate;
          
          respFormat.creatorIPAddress = element.creatorIPAddress ? element.creatorIPAddress : "";
          respFormat.createdBy = element.createdBy ? element.createdBy : "";
          respFormat.creatorEmail = element.creatorEmail ? element.creatorEmail : "";
          respFormat.hitHistory = element.hitHistory ? element.hitHistory: [];
          respFormat.apiKey = element.apiKey 
          // this.listData.push(respFormat);
          this.listData = [...this.listData,respFormat]
          
        });

        this.serv.getConfig(this.token).subscribe(res => {
          // console.log("Config Response => ",res)
          var resp:any;
          resp = res;
          // var adminFlag = false;
          let configList = []
          resp.forEach(element => {
            // console.log("API KEY =>",element.apiKey)
            // console.log("emailId => ",element.emailId)
            // console.log("Description => ",element.desc)
            var email = element.emailId
            var apiKey = element.apiKey
            if(email.indexOf(this.userEmail.toLowerCase()) != "-1"){
              // console.log("Inside")
              // console.log(apiKey)
              configList.push(apiKey)
            }
            // if(email.indexOf(this.userEmail) != "-1"){
            //   if(element.apiKey == "admin"){
            //     console.log("ADMIN")
            //     adminFlag = true;
            //   }
            //   else{
            //     this.listData = this.listData.filter(s => s.creatorEmail === this.userEmail && s.apiKey === apiKey)
            //   }
            // }
            // else{
            //   this.listData = this.listData.filter(s => s.creatorEmail === this.userEmail)
            // }
            // this.data = [...this.listData];
          })
          // console.log("Final Config =>",configList)

          if(configList.length > 0){
            // console.log("configList => ",configList)
            if( configList.indexOf("admin") > -1 ) {
              // console.log("admin access");
            }
            else{
              // console.log("API key is assigned")
              // console.log("this.listData => ",this.listData)
              this.listData = this.listData.filter(s => ((s.creatorEmail === this.userEmail) || (configList.indexOf(s.apiKey) > -1)))
            }
          }
          else{
            // console.log("No Special Access Provided!")
            this.listData = this.listData.filter(s => s.creatorEmail === this.userEmail)
          }

          this.listData.forEach(element => {
            element.apiKeyDesc = ""
            resp.forEach(ele => {
              // console.log("inside ele")
              // console.log("API key element ",element.apiKey)
              // console.log("API key ele ",ele.apiKey)
              if(element.apiKey === ele.apiKey){
                // console.log("API key is same ",element.apiKey)
                // console.log("API key is same ",ele.apiKey)
                element.apiKeyDesc = ele.desc
              }
            })
          })

          this.data = [...this.listData];
          // console.log("this.data => ",this.data)
        })

        // if(this.userEmail != "NA"){
        // this.listData = this.listData.filter(s => s.creatorEmail === this.userEmail)
        // }
        
        
        this.spinner.hide();
        
      },(err)=>{
        console.log("Error occurred while calling API to get details ",err)
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API is down, Please try again later!' });
        this.spinner.hide();
      });
  
  
    },(err)=>{
      console.log("Server is down ",err)
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API is down, Please try again later!!' });
      this.spinner.hide();
    })
  }

  linkClicked(header,data){
    
    if(header == "hitCount"){
      // console.log("header => ",header)
      // console.log("data => ",data)
      // this.email = data["creatorEmail"];
      // this.ip = data["creatorIPAddress"];
      let hitHistorytempList = []
      this.temp = []
      hitHistorytempList = data["hitHistory"]
      hitHistorytempList.forEach(element => {
        var format = {
          "hit": "",
          "time": "",
          "clientIP": "",
          "queryString":""
        }
        format.hit = element.hit;
        // format.time = element.time  ? new Date(element.time).toLocaleString() : element.time;
        // format.time = element.time  ? moment(element.time).fromNow()+" ("+new Date(element.time).toLocaleString()+")" : element.time;
        format.time = element.time  ? moment(element.time).fromNow()+" "+this.datePipe.transform(new Date(element.time),"dd-MM-yy HH:mm") : element.time;
        // format.time = element.time  ? moment(element.time).fromNow()+" ("+this.datePipe.transform(new Date(element.time),"dd/MM/yy HH:mm")+")" : element.time;
        
        format.queryString = element.queryString ? element.queryString : "";
        format.clientIP = element.clientIP;
        this.temp.push(format)
      })
      this.hitHistoryList = this.temp
      // console.log("hitHistoryList =>",this.hitHistoryList )
      this.urlDetailsPopupHeader = "Tiny URL hit history for "+ data["shorten_url"] ;
      this.dialogVisible = true;
    }
    // else if(header == "actual_url" || header == "shorten_url"){
    //   console.log("refresh data")
    //   this.fetchAllDetails()
    // }
  }

  urlDetailsYesClick(){
    this.dialogVisible = false;
  }
  deleteUrlClicked(row){
    this.deleteRow = ""
    this.row= {}
    this.row = row
    // console.log("delete clicked")
    // console.log(row)
    this.deleteRow = row["shorten_url"]
    this.deleteDialogVisible = true;
  }
  deleteUrlYesClick(){
    this.spinner.show();
    // console.log("Delete URL Yes Clicked!")
    // console.log(this.row)
    var tinyUrl = this.row["shorten_url"]
    // console.log("tinyUrl => ",tinyUrl)
    var requestId = tinyUrl.split("/").pop();
    // console.log("requestId => ",requestId)
    this.serv.tokenAPI().subscribe(res=>{
      
      this.token = res["access_token"];
      this.serv.deleteTinyURL(requestId,this.token).subscribe(res => {
        // console.log("Delete Res =>",res)
        this.spinner.hide();
        this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: 'Record has been deleted successfully!' });
      
      },(error)=>{
        console.log("Error occurred while delete api call ",error)
        this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API is down, Please try again later!!' });
        this.spinner.hide();
      })

    },(error)=>{
      console.log("Error occurred while token creation for delete ",error)
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API is down, Please try again later!!' });
      this.spinner.hide();
    })


    const index = this.data.indexOf(this.row, 0);
    if (index > -1) {
      this.data.splice(index, 1);
    }

    this.deleteDialogVisible = false;
  }

  editUrlClicked(row){
    this.editRow = row;
    // console.log("Row to be edited =>",row)
    this.domain = row["shorten_url"].substr(0, row["shorten_url"].lastIndexOf("/"));
    // console.log("domain => ",this.domain)
    this.requestId = row["shorten_url"].split("/").pop();
    this.longUrl = row["actual_url"]
    this.compLongURL = row["actual_url"]
    this.editDialogVisible = true;

  }

  async editUrlYesClick(){

    // var regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var isURL = this.regex.test(this.longUrl);
    if(isURL){
    if(this.compLongURL === this.longUrl){
      this.service.add({ key: 'tst', severity: 'info', summary: 'Info Message', detail: 'No changes made in Long URL!' });
    }
    else{
      this.spinner.show();
      let resIP
      try{
        resIP = await this.serv.getIPAddress().toPromise();
      }
      catch(error){

      }
      this.serv.tokenAPI().subscribe(res=>{
        this.token = res["access_token"];
        var editRequestBody = {
          "ip": resIP["ip"],
          "requestId": this.requestId,
          "url": this.longUrl,
          "updatedBy": this.userName,
          "updatedEmail": this.userEmail
        }
        
        this.serv.editLongURL(editRequestBody,this.token).subscribe(res => {
          // console.log("Edit Response => ",res)

          const index = this.data.indexOf(this.editRow, 0);
          // console.log("index of edited row => ",index);
          this.data[index]["actual_url"] = this.longUrl;

          this.spinner.hide();
          this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: res["message"] });
        },(error)=>{
          console.log("Error occurred while edit api call ",error)
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API is down, Please try again later!!' });
          this.spinner.hide();
        }
        )
      },
        (error)=>{
          console.log("Error occurred while token creation for edit ",error)
          this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'API is down, Please try again later!!' });
          this.spinner.hide();
        })
      // this.service.add({ key: 'tst', severity: 'success', summary: 'Success Message', detail: 'Record has been updated successfully!' });
        
    }
    this.editDialogVisible = false;
  }
    else{
      this.service.add({ key: 'tst', severity: 'error', summary: 'Error Message', detail: 'Please pass valid Long URL!' });
    }
    
  }
  exportUrlList(e: Table) {
    // console.log("Export Clicked")
    // console.log("Table data => ",e)
    let tblData:any = []; 
    // tblData = Object.assign({},e["value"]);
    tblData = JSON.parse( JSON.stringify(e["value"]) )
    // console.log("before tblData => ",tblData)
    
    // let dateReg = /\((.*)\)/;
    // let dateReg = /(\d{2}([.\-/])\d{2}([.\-/])\d{2}[ ]\d{2}[:]\d{2})/g
    // 17-04-2021 10:50

    e.value.forEach((c) => {
      // console.log("c=>",c)
      if (c["created_on"]) {
        c["created_on"] = c["created_on"].match(this.dateReg);
      }
      if (c["lastHitDate"]) {
        c["lastHitDate"] = c["lastHitDate"].match(this.dateReg);
      }
      // if (c["created_on"]) {
      //   c["created_on"] = c["created_on"].match(dateReg).pop();
      // }
      // if (c["lastHitDate"]) {
      //   c["lastHitDate"] = c["lastHitDate"].match(dateReg).pop();
      // }
    })
    e.exportCSV();
    e["value"] = tblData
    // console.log("after tblData => ",tblData)
    // e = tblData;
  }
  exportHitHistort(e: Table) {

    let tblData:any = [];
    tblData = JSON.parse( JSON.stringify(e["value"]) )
    // let dateReg = /(\d{2}([.\-/])\d{2}([.\-/])\d{2}[ ]\d{2}[:]\d{2})/g
    e.value.forEach((c) => {
      // c["time"] = c["time"].match(/\((.*)\)/).pop();
      c["time"] = c["time"].match(this.dateReg);
    })
   
    e.exportCSV();
    e["value"] = tblData

  }

}
